import * as React from 'react';
import useAxios from 'axios-hooks';
import { Drupal } from '@typings/drupal';
import { useEffect } from 'react';
import { log } from '@hooks/logger';
import { Helmet } from '@external/react-helmet-async';
import { OneColumn } from '@components/Layouts/OneColumn';
import Title from '@components/Title';
import { useTranslation } from '@external/react-i18next';
import { LogLevel } from '@typings/graphql';

const OffersPage: React.FC = () => {
  const { t } = useTranslation();
  const [{ data }] = useAxios<Drupal.GlobalRewardSettingsOutput>({
    url: `${process.env.GATSBY_BACKEND_APP_BASE_URL}/en/restapi/rotary-global-rewards`,
    withCredentials: true,
  });

  useEffect(() => {
    if (data) {
      const script = document.createElement('script');
      script.async = true;
      script.src = '//app.perkhub.com/assets/widgets_loader.js';
      const entry = document.getElementsByTagName('script')[0];
      // @ts-ignore
      entry.parentNode.insertBefore(script, entry);
    }
  }, [data]);

  if (data) {
    log({
      level: LogLevel.Debug,
      message: `Drupal: get rotary global rewards, request to ${process.env.GATSBY_BACKEND_APP_BASE_URL}/en/restapi/rotary-global-rewards`,
      other: {
        requestData: {
          withCredentials: true,
        },
        response: data,
      },
    });

    // @ts-ignore
    window.perkhubSettings = {
      ...data,
      ...data.ssoData,
      // @ts-ignore
      containerIDPrefix: 'perkhub-widget-',
      domain: data.url,
    };
  }

  return (
    <OneColumn>
      <Title>
        {t('global.rotary_rewards_page_title', 'Rotary Global Rewards')}
      </Title>
      <div className="perkhub-container">
        <Helmet>
          <meta
            name="verification"
            content={process.env.PERKHUB_ZANOX_VERIFICATION}
          />
        </Helmet>
        <div
          className="perkhub-widget"
          id={`perkhub-widget-${process.env.PERKHUB_APP_KEY}`}
        ></div>
      </div>
    </OneColumn>
  );
};

export default OffersPage;
